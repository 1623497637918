@import "colors";

.contact {
    height: 100vh;
    width: 100%;
    background-image: url("../assets/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    >form {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 2rem;
        width: 60%;
        height: 80%;
        justify-content: center;

        >h2 {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-family: "Roboto";
            margin: 2rem 0;
        }

        >input,
        >textarea,
        >button {
            padding: 1rem;
            outline: none;
            border: 1px solid rgb(202, 202, 202);
            margin: 0.5rem;
            width: 60%;
            border-radius: 5px;
        }

        >button {
            background-color: $red;
            border: none;
            cursor: pointer;
            color: white;
            font-size: 1rem;

            &:hover {
                background-color: $reddark;
            }
        }
    }

    .formBorder {
        width: 200px;
        height: 80%;
        background-color: white;
        border-radius: 0 200px 200px 0;
        position: relative;

        >div {
            width: 300px;
            height: 237px;
            position: absolute;
            top: 55%;
            transform: translate(50%, -50%);
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.345));
            right: 100%;

            >img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .contact {
        height: 70vh;

        >form {
            width: 100%;
            height: 100%;

            >input,
            >textarea,
            >button {
                padding: 1rem;
                outline: none;
                border: 1px solid rgb(202, 202, 202);
                margin: 0.5rem;
                width: 100%;
                border-radius: 5px;
            }
        }

        .formBorder {
            display: none;
        }

    }
}