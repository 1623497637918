@import "colors";
.cart{
    background-color: $pink;
    width: 100%;
    padding: 1rem 0;

    >main{
        height: 100%;
        background-color: white;
        max-width: 900px;
        margin: auto;
        padding: 2rem;

        article{
            padding: 2rem;
        >div{
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
        }
        >a{
            background-color: $red;
            color: white;
            padding:0.5rem 1rem;
            border-radius: 5px;
            text-align: center;
            margin: 1rem;
            float: right;
        }
        }
    }
}
.cartItem{
    background-color: rgb(246,246,246);
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 5px;
    max-width: 100vw;
    min-width: 54vw;
    >div{
        display: flex;
        align-items: center;
        >h4{
            font-weight: 200;
            letter-spacing: 2px;
        }
        >img{
            max-height: 50px;
        }
        >input{
            text-align: center;
            width: 4rem;
            height: 2rem;
            padding: 0.5rem;
            border: none;
            outline: none;
            margin: 0.25rem;
            border-radius: 5px;
            color: $red;
        }
        >button{
            cursor: pointer;
            border: none;
            padding: 0.5rem;
            background-color: $dark;
            color: white;
            border-radius: 5px;
        }
    }   
}
@media screen and (max-width:500px) {
    .cart{
        margin: 0rem 0rem 3rem 0rem;
        >main{
            padding: 0rem 0.4rem 2rem 0.4rem;
          article{
            >a{
                float: none;
                display: block;
                width: 100%;
            }
          }  
        }
    }
    .cartItem {
        margin: 1rem 0.4rem;
    }
    .cartItem > div > input {
        text-align: center;
        width: 2rem;
    }
    .cartItem > div > h4 {
        font-size: 1rem;
        font-weight: 200;
        letter-spacing: 2px;
    }
}