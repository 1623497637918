@import "colors";

.founder {
    width: 100%;
    height: 80vh;
    background-color: $red;

    >div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        width: 50%;
        margin: auto;
        text-align: center;

        >img {
            border-radius: 50%;
        }

        h3 {
            font-size: 1.3rem;
            padding: 2rem 0 1rem 0;
        }

        >p {
            letter-spacing: 2px;
            font-size: 0.9rem;
            font-family: cursive;
            line-height: 200%;
        }
    }

}