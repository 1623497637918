@import "colors";
.login{
    height: 100vh;
    width: 100%;
    background-image: url("../assets/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    >button{
        background-color: white;
        outline: none;
        border: none;
        color: $lightdark;
        border-radius: 10px;
        padding: 1rem 2rem;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        >svg{
            margin-left: 1rem;
        }
    &:hover{
        color: $reddark;
    }
    }
}