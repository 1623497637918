@import "colors";

nav {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.229);
    background-color: white;

    >div {
        display: flex;
        align-items: center;

        >svg {
            color: $red;
            font-size: 2.5rem;
        }

        >a {
            margin: 0 2rem;
            color: $dark;
            transition: color 0.3s;

            &:hover {
                color: $lightdark;
            }

            >svg {
                color: $red;
                transition: inherit;
                font-size: 1.5rem;

                &:hover {
                    color: $lightdark;
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    nav{
        padding: 0.5rem;
        >div{
            >a{
                margin: 0.5rem;
            }
        }
    }
}