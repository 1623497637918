@import "colors";
.profile {
    background-color: $pink;
    width: 100%;
    // height: 100vh;
  
    > main {
      max-width: 900px;
      height: 100%;
      background-color: white;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem;
  
      > img {
        height: 15rem;
        width: 15rem;
        border-radius: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.146);
        margin: 4rem;
      }
  
      > h5 {
        font-size: 2rem;
      }
      > div {
        margin: 2rem;
        > a {
          background-color: $red;
          color: white;
          padding: 0.7rem 1.5rem;
          font-size: 1rem;
          border-radius: 5px;
          display: flex;
          align-items: center;
          svg {
            margin-right: 0.5rem;
          }
          &:hover {
            background-color: $reddark;
          }
        }
      }
  
      > button {
        border: none;
        outline: none;
        padding: 0.7rem 1.5rem;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        font-size: 1rem;
        &:hover {
          background-color: rgba(245, 245, 245, 0.864);
        }
      }
    }
  }