@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&family=Tenor+Sans&family=Ubuntu:ital,wght@0,300;0,400;1,300&display=swap');
@import "colors";
*{
    margin: 0;
    padding: 0;
    font-family: "ubuntu",sans-serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
a{
    text-decoration: none;
}


.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > svg {
      font-size: 15rem;
      color: $red;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.3;
    }
  
    > div {
      font-size: 2rem;
    }
  }
  .notFound {
  background-color: $pink;
  width: 100%;
  height: 80vh;
  > main {
    background-color: white;
    height: 100%;
    max-width: 900px;
    margin: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      margin: 3rem;

      > svg {
        font-size: 4rem;
      }

      > h1 {
        text-transform: uppercase;
        align-items: center;
        font: 100 3rem "Roboto";
        margin: 0 1rem;
      }
    }

    > a {
      background-color: $red;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin: 1rem;
      text-align: center;
      font-size: 1.1rem;
    }
  }
}